import Head from "next/head";
import { useRouter } from "next/router";
import { useMemo } from "react";
const PAGES = [
	{
		id: "home",
		title: "ג'יין - מלאי רפואי, השוואת מחירים, והזמנת אונליין",
		description:
			"רשימת מלאי מתעדכנת בזמן אמת של כל מוצרי  הרפואי שזמינים כרגע לרכישה בבתי המרקחת ברחבי הארץ. ניתן לסנן ולמיין את הטבלה לפי סוג מוצר, מחיר, קטגוריית מינון, אפיון אינדיקה / סאטיבה, שיטת גידול, THC, CBD, ארץ ייצור, ועוד.",
	},
	{
		id: "products",
		title: "מוצרי רפואי | ג'יין - מלאי רפואי, השוואת מחירים, והזמנת אונליין",
		description:
			"רשימת מלאי מתעדכנת בזמן אמת של כל מוצרי  הרפואי שזמינים כרגע לרכישה בבתי המרקחת ברחבי הארץ. ניתן לסנן ולמיין את הטבלה לפי סוג מוצר, מחיר, קטגוריית מינון, אפיון אינדיקה / סאטיבה, שיטת גידול, THC, CBD, ארץ ייצור, ועוד.",
	},
	{
		id: "manufacturers",
		title:
			"מלאי קנאביס רפואי לפי יצרן | ג'יין - מלאי קנאביס רפואי, השוואת מחירים, והזמנת קנאביס אונליין",
		description:
			"רשימת מלאי מתעדכנת בזמן אמת של כל מוצרי הקנאביס הרפואי של היצרנים השונים אשר זמינים לרכישה כרגע. לחצו על שם היצרן כדי לצפות ברשימת המלאי של כל מוצרי הקנאביס שלו שזמינים לרכישה כעת.",
	},
	{
		id: "manufacturersById",
		title:
			"מוצרי קנאביס של {ID} | ג'יין - מלאי קנאביס רפואי, השוואת מחירים, והזמנת קנאביס אונליין",
		description:
			"רשימת מלאי מתעדכנת בזמן אמת של כל מוצרי הקנאביס הרפואי של חברת {ID} אשר זמינים כרגע לרכישה בבתי המרקחת ברחבי הארץ. ניתן לסנן ולמיין את הטבלה לפי סוג מוצר, מחיר, קטגוריית מינון, אפיון אינדיקה / סאטיבה, שיטת גידול, THC, CBD, ארץ ייצור, ועוד.",
	},
	{
		id: "marketers",
		title:
			"מלאי קנאביס רפואי לפי משווק | ג'יין - מלאי קנאביס רפואי, השוואת מחירים, והזמנת קנאביס אונליין",
		description:
			"רשימת מלאי מתעדכנת בזמן אמת של כל מוצרי הקנאביס הרפואי של המשווקים השונים אשר זמינים לרכישה כרגע. לחצו על שם המשווק כדי לצפות ברשימת המלאי של כל מוצרי הקנאביס שלו שזמינים לרכישה כעת.",
	},
	{
		id: "marketersById",
		title:
			"מוצרי קנאביס של {ID} | ג'יין - מלאי קנאביס רפואי, השוואת מחירים, והזמנת קנאביס אונליין",
		description:
			"רשימת מלאי מתעדכנת בזמן אמת של כל מוצרי הקנאביס הרפואי של חברת {ID} אשר זמינים כרגע לרכישה בבתי המרקחת ברחבי הארץ. ניתן לסנן ולמיין את הטבלה לפי סוג מוצר, מחיר, קטגוריית מינון, אפיון אינדיקה / סאטיבה, שיטת גידול, THC, CBD, ארץ ייצור, ועוד.",
	},
	{
		id: "shabbat",
		title:
			"בתי מרקחת פתוחים בשבת | ג'יין - מלאי קנאביס רפואי, השוואת מחירים, והזמנת קנאביס אונליין",
		description:
			"רשימת בתי המרקחת שמוכרים קנאביס רפואי ופתוחים גם במהלך שבת, כולל המלאי עדכני שלהם, שעות פתיחה, וטלפון.",
	},
	{
		id: "flower",
		title:
			"תפרחות קנאביס רפואי | ג'יין - מלאי קנאביס רפואי, השוואת מחירים, והזמנת קנאביס אונליין",
		description:
			"רשימת מלאי מתעדכנת בזמן אמת של כל תפרחות הקנאביס הרפואי שזמינות כרגע לרכישה בבתי המרקחת ברחבי הארץ. ניתן לסנן ולמיין את הטבלה לפי מחיר, קטגוריית מינון, אפיון אינדיקה / סאטיבה, שיטת גידול, THC, CBD, ארץ ייצור, ועוד.",
	},
	{
		id: "oil",
		title:
			"שמני הקנאביס הרפואי | ג'יין - מלאי קנאביס רפואי, השוואת מחירים, והזמנת קנאביס אונליין",
		description:
			"רשימת מלאי מתעדכנת בזמן אמת של כל שמני הקנאביס הרפואי שזמינים כרגע לרכישה בבתי המרקחת ברחבי הארץ. ניתן לסנן ולמיין את הטבלה לפי מחיר, קטגוריית מינון, אפיון אינדיקה / סאטיבה, שיטת גידול, THC, CBD, ארץ ייצור, ועוד.",
	},
	{
		id: "preroll",
		title:
			"גליליות קנאביס הרפואי | ג'יין - מלאי קנאביס רפואי, השוואת מחירים, והזמנת קנאביס אונליין",
		description:
			"רשימת מלאי מתעדכנת בזמן אמת של כל גליליות הקנאביס הרפואי שזמינות כרגע לרכישה בבתי המרקחת ברחבי הארץ. ניתן לסנן ולמיין את הטבלה לפי מחיר, קטגוריית מינון, אפיון אינדיקה / סאטיבה, שיטת גידול, THC, CBD, ארץ ייצור, ועוד.",
	},
	{
		id: "thc",
		title:
			"זני הקנאביס החזקים ביותר – מוצרי הקנאביס הרפואי שמכילים הכי הרבה THC | ג'יין - מלאי קנאביס רפואי, השוואת מחירים, והזמנת קנאביס אונליין",
		description:
			"רשימת מלאי מתעדכנת בזמן אמת של מוצרי הקנאביס הרפואי החזקים ביותר, עם ריכוז ה-THC הגבוה ביותר באצווה הנוכחית שלהם לפי בדיקות מעבדה. ניתן לסנן ולמיין את הטבלה לפי סוג מוצר, מחיר, קטגוריית מינון, אפיון אינדיקה / סאטיבה, שיטת גידול, THC, CBD, ארץ ייצור, ועוד.",
	},
	{
		id: "cbd",
		title:
			"קנאביס עשיר CBD - מוצרי הקנאביס הרפואי שמכילים הכי הרבה CBD | ג'יין - מלאי קנאביס רפואי, השוואת מחירים, והזמנת קנאביס אונליין",
		description:
			"רשימת מלאי מתעדכנת בזמן אמת של מוצרי הקנאביס הרפואי עם ריכוז ה-CBD הגבוה ביותר באצווה הנוכחית שלהם לפי בדיקות מעבדה. ניתן לסנן ולמיין את הטבלה לפי סוג מוצר, מחיר, קטגוריית מינון, אפיון אינדיקה / סאטיבה, שיטת גידול, THC, CBD, ארץ ייצור, ועוד.",
	},
	{
		id: "price",
		title:
			"מוצרי הקנאביס הרפואי הזולים ביותר | ג'יין - מלאי קנאביס רפואי, השוואת מחירים, והזמנת קנאביס אונליין",
		description:
			"רשימת מלאי מתעדכנת בזמן אמת של מוצרי הקנאביס הרפואי הזולים ביותר שזמינים לרכישה כרגע בבתי המרקחת בארץ, מסודרים מהזול ביותר ליקר ביותר. ניתן לסנן ולמיין את הטבלה לפי סוג מוצר, קטגוריית מינון, אפיון אינדיקה / סאטיבה, שיטת גידול, THC, CBD, ארץ ייצור, ועוד.",
	},
	// {
	// 	id: 'sale',
	// 	title: "מבצעי הקנאביס הרפואי המשתלמים ביותר | ג'יין - מלאי קנאביס רפואי, השוואת מחירים, והזמנת קנאביס אונליין",
	// 	description: "רשימת מלאי מתעדכנת בזמן אמת של מוצרי הקנאביס הרפואי שנמכרים בהנחה כרגע בבתי המרקחת בארץ, מסודרים מאחוז ההנחה הגבוה ביותר לנמוך ביותר. ניתן לסנן ולמיין את הטבלה לפי סוג מוצר, מחיר, קטגוריית מינון, אפיון אינדיקה / סאטיבה, שיטת גידול, THC, CBD, ארץ ייצור, ועוד.",
	// },
	{
		id: "storesDiscounts",
		title:
			"חנויות הקנאביס הרפואי הזולות ביותר | ג'יין - מלאי קנאביס רפואי, השוואת מחירים, והזמנת קנאביס אונליין",
		description:
			"רשימה מתעדכנת בזמן אמת של חנויות הקנאביס הזולות ביותר בארץ, מסודרות לפי ממוצע ההנחות על מוצרים הגבוה ביותר.",
	},
	{
		id: "signup",
		title:
			"הרשמה - רוקחים | ג'יין - מלאי קנאביס רפואי, השוואת מחירים, והזמנת קנאביס אונליין",
		description: "",
	},
	{
		id: "productById",
		title:
			" {ID} - | ג'יין - מלאי קנאביס רפואי, השוואת מחירים, והזמנת קנאביס אונליין",
		description:
			"איתור מלאי קנאביס רפואי מכל בתי המרקחת בארץ בזמן אמת, הזמנת משלוחים עד הבית אונליין, השוואת מחירים ומבצעים.",
	},
	{
		id: "storeById",
		title:
			"{ID} - | ג'יין - מלאי קנאביס רפואי, השוואת מחירים, והזמנת קנאביס אונליין",
		description:
			"רשימת מלאי מתעדכנת בזמן אמת של מוצרי הקנאביס הרפואי שזמינים לרכישה כרגע בבית מרקחת {ID}, שעות פתיחה, כתובת, טלפון. ניתן לסנן ולמיין את הטבלה לפי סוג מוצר, מחיר, קטגוריית מינון, אפיון אינדיקה / סאטיבה, שיטת גידול, THC, CBD, ארץ ייצור, ועוד.",
	},
	{
		id: "stores",
		title:
			"בתי מרקחת שמוכרים קנאביס רפואי - מלאי עדכני, שעות פתיחה, פרטי יצירת קשר | ג'יין - מלאי קנאביס רפואי, השוואת מחירים, והזמנת קנאביס אונליין",
		description:
			"רשימת כל בתי המרקחת שמוכרים קנאביס רפואי, לפי תאריך עדכון אחרון של המלאי שלהם באתר (העדכני ביותר מופיע למעלה). לחצו על בית המרקחת כדי לראות את רשימת מלאי הקנאביס הרפואי שלו ופרטים נוספים כמו שעות פתיחה, כתובת, וטלפון.",
	},
	{
		id: "cities",
		title:
			"מלאי קנאביס רפואי לפי עיר | ג'יין - מלאי קנאביס רפואי, השוואת מחירים, והזמנת קנאביס אונליין",
		description:
			"רשימת מלאי מתעדכנת בזמן אמת של כל מוצרי הקנאביס הרפואי שזמינים לרכישה כרגע בכל אחת מהערים בארץ. לחצו על שם העיר כדי לצפות ברשימת המלאי של כל מוצרי הקנאביס שזמינים בה.",
	},
	{
		id: "cityById",
		title:
			"מלאי קנאביס רפואי ב-{ID} | ג'יין - מלאי קנאביס רפואי, השוואת מחירים, והזמנת קנאביס אונליין",
		description:
			"רשימת מלאי מתעדכנת בזמן אמת של כל מוצרי הקנאביס הרפואי שזמינים לרכישה כרגע בבתי המרקחת ב-{ID}. ניתן לסנן ולמיין את הטבלה לפי סוג מוצר, מחיר, קטגוריית מינון, אפיון אינדיקה / סאטיבה, שיטת גידול, THC, CBD, ארץ ייצור, ועוד.",
	},
	{
		id: "productsDiscount",
		title:
			"מוצרי הקנאביס הרפואי המוזלים ביותר | ג'יין - מלאי קנאביס רפואי, השוואת מחירים, והזמנת קנאביס אונליין",
		description:
			"רשימת מלאי מתעדכנת בזמן אמת של מוצרי הקנאביס הרפואי המוזלים ביותר שזמינים לרכישה כרגע בבתי המרקחת בארץ, מסודרים מהזול ביותר ליקר ביותר. ניתן לסנן ולמיין את הטבלה לפי סוג מוצר, קטגוריית מינון, אפיון אינדיקה / סאטיבה, שיטת גידול, THC, CBD, ארץ ייצור, ועוד.",
	},
	{
		id: "storesSales",
		title:
			"מבצעי בתי מרקחת קנאביס רפואי | ג'יין - מלאי קנאביס רפואי, השוואת מחירים, והזמנת קנאביס אונליין",
		description:
			"רשימה מתעדכנת בזמן אמת של כל המבצעים על קנאביס רפואי שקיימים כרגע בבתי המרקחת בארץ. לחצו על בית המרקחת כדי לראות את רשימת המבצעים שלו.",
	},
	{
		id: "productsByCityId",
		title:
			"מוצרי קנאביס רפואי ב{ID} | ג'יין - מלאי קנאביס רפואי, השוואת מחירים, והזמנת קנאביס אונליין",
		description:
			"רשימת מלאי מתעדכנת בזמן אמת של כל מוצרי הקנאביס הרפואי שזמינים לרכישה כרגע בבתי המרקחת ב{ID}. ניתן לסנן ולמיין את הטבלה לפי סוג מוצר, מחיר, קטגוריית מינון, אפיון אינדיקה / סאטיבה, שיטת גידול, THC, CBD, ארץ ייצור, ועוד.",
	},
	{
		id: "newest",
		title:
			"מוצרי הקנאביס הרפואי החדשים ביותר | ג'יין - מלאי קנאביס רפואי, השוואת מחירים, והזמנת קנאביס אונליין",
		description:
			"רשימת מלאי מתעדכנת בזמן אמת של מוצרי הקנאביס הרפואי החדשים ביותר שזמינים לרכישה כרגע בבתי המרקחת בארץ. ניתן לסנן ולמיין את הטבלה לפי סוג מוצר, קטגוריית מינון, אפיון אינדיקה / סאטיבה, שיטת גידול, THC, CBD, ארץ ייצור, ועוד.",
	},
	{
		id: "soon",
		title:
			"מוצרי קנאביס רפואי שיוצאים בקרוב | ג'יין - מלאי קנאביס רפואי, השוואת מחירים, והזמנת קנאביס אונליין",
		description:
			"רשימה של מוצרי קנאביס רפואי חדשים שעתידים לצאת לשוק בקרוב. לחצו על מוצר כדי לראות פרטים נוספים.",
	},
	{
		id: "strains",
		title:
			"מלאי קנאביס רפואי לפי גנטיקה | ג'יין - מלאי קנאביס רפואי, השוואת מחירים, והזמנת קנאביס אונליין",
		description:
			"רשימת מלאי מתעדכנת בזמן אמת של כל מוצרי הקנאביס הרפואי שזמינים לרכישה בבתי המרקחת בארץ, בחלוקה לפי זנים (גנטיקות). לחצו על שם הזן כדי לראות את כל מוצרי הקנאביס מזן זה שזמינים במלאי כרגע.",
	},
	{
		id: "categories",
		title:
			"מלאי קנאביס רפואי לפי גנטיקה | ג'יין - מלאי קנאביס רפואי, השוואת מחירים, והזמנת קנאביס אונליין",
		description:
			"רשימת מלאי מתעדכנת בזמן אמת של כל מוצרי הקנאביס הרפואי שזמינים לרכישה בבתי המרקחת בארץ, בחלוקה לפי מינונים. לחצו על מינון כדי לראות את כל מוצרי הקנאביס ממינון זה שזמינים במלאי כרגע.",
	},
	{
		id: "strainsById",
		title:
			"מוצרי קנאביס מזן {ID} | ג'יין - מלאי קנאביס רפואי, השוואת מחירים, והזמנת קנאביס אונליין",
		description:
			"רשימת מלאי מתעדכנת בזמן אמת של כל מוצרי הקנאביס הרפואי מזן {ID} שזמינים לרכישה בבתי המרקחת בארץ כעת. לחצו על מוצר כדי לראות באיזה בתי מרקחת הוא זמין.",
	},
	{
		id: "desiredCart",
		title:
			"בניית הסל שלי | ג'יין - מלאי קנאביס רפואי, השוואת מחירים, והזמנת קנאביס אונליין",
		description:
			"בניית עגלה לפי רשימת מוצרים מבית מרקחת אחד, כדי להשוות מחירים שלהם בבתי המרקחת בארץ ולהזמין אותם.",
	},
	{
		id: "balanced",
		title: "זני קנאביס מאוזנים - מוצרי קנאביס שמכילים יחס שווה של THC ו-CBD",
		description:
			"רשימת מלאי מתעדכנת בזמן אמת של מוצרי הקנאביס הרפואי המאוזנים (בעלי יחס THC ו-CBD שווה) הזמינים במלאי בבתי המרקחת בארץ כרגע. ניתן לסנן ולמיין את הטבלה לפי סוג מוצר, מחיר, קטגוריית מינון, אפיון אינדיקה / סאטיבה, שיטת גידול, THC, CBD, ארץ ייצור, ועוד.",
	},
	{
		id: "productViews",
		title: "רשימת המוצרים הנצפים ביותר באתר במהלך השעה/היממה האחרונה",
		description:
			"רשימת מוצרים הנצפים ביותר באתר במהלך השעה/היממה האחרונה והמוצרים שהכי התעניינו בהם המשתמשים באתר. ניתן לסנן ולמיין את הטבלה לפי סוג מוצר, מחיר, קטגוריית מינון, אפיון אינדיקה / סאטיבה, שיטת גידול, THC, CBD, ארץ ייצור, ועוד.",
	},
	{
		id: "batches",
		title:
			"מלאי קנאביס רפואי לפי אצווה | ג'יין - מלאי קנאביס רפואי, השוואת מחירים",
		description:
			"רשימת מלאי מתעדכנת בזמן אמת של כל מוצרי הקנאביס הרפואי שזמינים כרגע לרכישה בבתי המרקחת בארץ, בחלוקה לפי אצוות המוצר השונות שקיימות כרגע בשוק. לחצו על המוצר כדי לראות פרטים נוספים.",
	},
	{
		id: "reviews",
		title: "ביקורות קנאביס רפואי | ג'יין - מלאי קנאביס רפואי, השוואת מחירים",
		description:
			"רשימת כל הביקורות על מוצרי קנאביס רפואי שנכתבו על ידי רוכשים מאומתים באתר ג'יין. לחצו על ביקורת כדי לראות פרטים נוספים.",
	},
	{
		id: "greenhouse",
		title:
			"קנאביס רפואי בגידול חממה | ג'יין - מלאי קנאביס רפואי, השוואת מחירים, והזמנת קנאביס אונליין",
		description:
			"רשימת מלאי מתעדכנת בזמן אמת של כל תפרחות הקנאביס הרפואי מגידול חממה שזמינים כרגע לרכישה בבתי המרקחת ברחבי הארץ. ניתן לסנן ולמיין את הטבלה לפי מחיר, קטגוריית מינון, אפיון אינדיקה / סאטיבה, THC ,CBD, ארץ ייצור, ועוד.",
	},
	{
		id: "indoor",
		title:
			"קנאביס רפואי בגידול פנים (אינדור) | ג'יין - מלאי קנאביס רפואי, השוואת מחירים, והזמנת קנאביס אונליין",
		description:
			"רשימת מלאי מתעדכנת בזמן אמת של כל תפרחות הקנאביס הרפואי מגידול פנים (אינדור) שזמינים כרגע לרכישה בבתי המרקחת ברחבי הארץ. ניתן לסנן ולמיין את הטבלה לפי מחיר, קטגוריית מינון, אפיון אינדיקה / סאטיבה, THC ,CBD, ארץ ייצור, ועוד.",
	},
	{
		id: "mixed",
		title:
			"קנאביס רפואי בגידול משולב | ג'יין - מלאי קנאביס רפואי, השוואת מחירים, והזמנת קנאביס אונליין",
		description:
			"רשימת מלאי מתעדכנת בזמן אמת של כל תפרחות הקנאביס הרפואי מגידול משולב שזמינים כרגע לרכישה בבתי המרקחת ברחבי הארץ. ניתן לסנן ולמיין את הטבלה לפי מחיר, קטגוריית מינון, אפיון אינדיקה / סאטיבה, THC ,CBD, ארץ ייצור, ועוד.",
	},
	{
		id: "mostViewed",
		title:
			"קנאביס רפואי בגידול משולב | ג'יין - מלאי קנאביס רפואי, השוואת מחירים, והזמנת קנאביס אונליין",
		description:
			"הנצפים תיאור: המוצרים הנצפים ביותר באתר ב-יום/שבוע/חודש האחרונים",
	},
];
const SEOHead = ({ title, description, image, url, richSnippetData }) => {
	return (
		<Head>
			<title>{title}</title>
			<meta name="description" content={description} />
			<meta
				name="viewport"
				content="width=device-width,initial-scale=1,minimum-scale=1,maximum-scale=3,shrink-to-fit=no"
			/>
			<link rel="canonical" href={url} />
			<meta charSet="UTF-8" />
			<meta name="language" content="he" />
			{/* Open Graph Facebook, LinkedIn, Instagram */}
			<meta property="og:title" content={title} />
			<meta property="og:description" content={description} />
			<meta property="og:url" content={url} />
			<meta property="og:image" content={image} />
			<meta property="og:image:width" content="1200" />
			<meta property="og:image:height" content="627" />
			<meta property="og:type" content="website" />
			{/* Twitter */}
			<meta name="twitter:title" content={title} />
			<meta name="twitter:description" content={description} />
			<meta name="twitter:card" content="summary_large_image" />
			<meta name="twitter:image" content={image} />
			{/* JSON-LD Rich Snippet */}
			{!!richSnippetData && (
				<script
					type="application/ld+json"
					dangerouslySetInnerHTML={{ __html: JSON.stringify(richSnippetData) }}
				/>
			)}
		</Head>
	);
};
const SEO = ({
	pageId,
	paramId = null,
	hasParamId = false,
	product = undefined,
	store = undefined,
}) => {
	const router = useRouter();
	const baseUrl = process.env.NEXT_PUBLIC_BASE_URL || "";
	const richSnippetData = useMemo(() => {
		if (
			product &&
			!!product.catalog_price &&
			!!product.main_img_url &&
			!!product.heb_name
		) {
			const hasPriceRange =
				product.from_price &&
				product.to_price &&
				product.from_price !== product.to_price;

			const offers = hasPriceRange
				? {
						"@type": "AggregateOffer",
						lowPrice: product.from_price,
						highPrice: product.to_price,
						priceCurrency: "ILS",
						availability: product.is_in_stock
							? "https://schema.org/InStock"
							: "https://schema.org/OutOfStock",
						offerCount: product.totalOffers || 1,
				  }
				: {
						"@type": "Offer",
						price: product.catalog_price,
						priceCurrency: "ILS",
						availability: product.is_in_stock
							? "https://schema.org/InStock"
							: "https://schema.org/OutOfStock",
				  };
			return {
				"@context": "https://schema.org",
				"@type": "Product",
				name: product.heb_name,
				image: product.main_img_url,
				sku: product.sku_code,
				category: product.category,
				brand: {
					"@type": "Brand",
					name:
						product.manufacturer_series_heb_name ?? product.marketer_heb_name,
				},
				hasMerchantReturnPolicy: {
					"@type": "MerchantReturnPolicy",
					returnPolicyCategory: "https://schema.org/NonRefundable",
				},
				offers: offers,
				aggregateRating: {
					"@type": "AggregateRating",
					ratingValue: product?.rating ?? 0,
					bestRating: 5,
					worstRating: 1,
					reviewCount: product?.ratingCount ?? 0,
				},
			};
		} else if (store) {
			const storeOpeningHours = store.opening_hours.workHours;
			const dayMapping = {
				1: "Sunday",
				2: "Monday",
				3: "Tuesday",
				4: "Wednesday",
				5: "Thursday",
				6: "Friday",
				7: "Saturday",
			};
			const openingHoursSpecification = [];
			Object.entries(storeOpeningHours).forEach(([day, periods]) => {
				if (periods[0] === "closed") {
					// If the business is closed on this day, we omit it
					return;
				}
				periods.forEach(period => {
					openingHoursSpecification.push({
						"@type": "OpeningHoursSpecification",
						dayOfWeek: dayMapping[day],
						opens: period.fromHour,
						closes: period.toHour,
					});
				});
			});
			return {
				"@context": "https://schema.org",
				"@type": "LocalBusiness",
				name: store.display_name,
				image: store.logo_url,
				telephone: store.phone_number,
				description: `רשימת מלאי מתעדכנת בזמן אמת של מוצרי הקנאביס הרפואי שזמינים לרכישה כרגע בבית מרקחת ${store.display_name}, שעות פתיחה, כתובת, טלפון. ניתן לסנן ולמיין את הטבלה לפי סוג מוצר, מחיר, קטגוריית מינון, אפיון אינדיקה / סאטיבה, שיטת גידול, THC, CBD, ארץ ייצור, ועוד.`,
				address: {
					"@type": "PostalAddress",
					streetAddress: `${store?.street_name} ${store?.street_num}`,
					addressLocality: store.city.heb_name,
					addressCountry: "ישראל",
				},
				hasMerchantReturnPolicy: {
					"@type": "MerchantReturnPolicy",
					returnPolicyCategory: "https://schema.org/NonRefundable",
				},
				...(store.latitude &&
					store.longitude && {
						geo: {
							"@type": "GeoCoordinates",
							latitude: store.latitude,
							longitude: store.longitude,
						},
					}),
				openingHoursSpecification: openingHoursSpecification,
				...(store.has_delivery && {
					deliveryTime: {
						"@type": "ShippingDeliveryTime",
						businessDays: {
							"@type": "OpeningHoursSpecification",
							dayOfWeek: [
								//todo: can be adjusted to store delivery days (required backend changes)
								"https://schema.org/Monday",
								"https://schema.org/Tuesday",
								"https://schema.org/Wednesday",
								"https://schema.org/Thursday",
								"https://schema.org/Friday",
								"https://schema.org/Saturday",
								"https://schema.org/Sunday",
							],
						},
					},
				}),
			};
		} else {
			return false;
		}
	}, [product, store]);

	const page = PAGES.find(page => page.id === pageId);

	let description = page?.description;
	let title = page?.title;
	let image = page?.image;

	let meta = {
		title: title || "Jane",
		description:
			description || "ג'יין - מלאי רפואי, השוואת מחירים, והזמנת אונליין",
		image: image || `${baseUrl}/jane-logo.svg`,
		url: `${baseUrl}${router.asPath}`,
	};

	if (paramId) {
		meta.title = title.replace("{ID}", paramId);
		meta.description = description.replace("{ID}", paramId);

		return (
			<SEOHead
				title={meta.title}
				description={meta.description}
				image={meta.image}
				url={meta.url}
				richSnippetData={richSnippetData}
			/>
		);
	}

	if (hasParamId && !paramId) {
		return null;
	}

	return (
		<SEOHead
			title={meta.title}
			description={meta.description}
			image={meta.image}
			url={meta.url}
			richSnippetData={richSnippetData}
		/>
	);
};

export default SEO;
